<template>
  <div>
    <base-section
      id="servicelist-account"
      space="16"
    >
      <v-container>
        <kdw-Statusbar-card
          v-model="snackbarUpdate"
          :snackbar-timeout="snackbarTimeout"
          :snackbar-message="snackbarMessage"
        />
        <v-row justify="space-between">
          <v-col
            cols="12"
            md="12"
          >
            <base-info-card :title="$t('serviceSP.mainTitle')" />
            <kdw-service-list-management
              @alertSnackBarMessage="alertSnackBarMessage($event)"
            />
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator'

  @Component({ name: 'ServicelistAccount' })
  class ServicelistAccount extends Vue {
    snackbarUpdate = false
    snackbarMessage = ''
    snackbarTimeout = 2000

    alertSnackBar (message, snackbarTimeout) {
      this.snackbarMessage = message
      this.snackbarUpdate = true
      this.snackbarTimeout = snackbarTimeout
    }

    alertSnackBarMessage (event) {
      this.alertSnackBar(event.message, event.snackbarTimeout)
    }
  }

  export default ServicelistAccount
</script>
